var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v1 px-2"},[_c('div',{staticClass:"auth-inner py-2"},[_c('b-card',{staticClass:"mb-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('b-img',{attrs:{"src":require('@/assets/images/logo/512.svg'),"width":"240","height":"240"}})],1),_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" Adventure starts here 🚀 ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" Make your app management easy and fun! ")]),_c('validation-observer',{ref:"registerForm"},[_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_c('b-form-group',{attrs:{"label":"Your Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false:null,"name":"register-email","placeholder":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Your Username","label-for":"username"}},[_c('validation-provider',{attrs:{"name":"username","rules":{required: true,  regex: /^[a-z0-9]+$/},"custom-messages":{regex: 'Username can only contain lowercase characters and numbers'}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"username","state":errors.length > 0 ? false:null,"name":"username","placeholder":""},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Your FB ID","label-for":"register-fb"}},[_c('validation-provider',{attrs:{"name":"register-fb","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"fbid","state":errors.length > 0 ? false:null,"name":"register-fb","placeholder":""},model:{value:(_vm.facebook),callback:function ($$v) {_vm.facebook=$$v},expression:"facebook"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Your full name","label-for":"register-name"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false:null,"name":"register-name","placeholder":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Password","label-for":"a-password"}},[_c('validation-provider',{attrs:{"name":"Password","id":"Password","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"id":"a-password","state":errors.length > 0 ? false:null,"type":"password","placeholder":"Password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('h5',{staticClass:"center"},[_vm._v("By clicking \"Sign Up\" you agree to "),_c('a',{attrs:{"href":"#"}},[_vm._v("Terms & Conditions")]),_vm._v(" and "),_c('a',{attrs:{"href":"#"}},[_vm._v("Privacy Policy .")])])]),_c('b-button',{attrs:{"variant":"primary","type":"submit","block":"","disabled":_vm.invalid}},[(_vm.processing)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Sign up ")],1)],1)],1),_c('b-card-text',{staticClass:"text-center mt-2"},[_c('span',[_vm._v("Already have an account? ")]),_c('b-link',{attrs:{"to":{name:'login'}}},[_c('span',[_vm._v("Sign in instead")])])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }