<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Register v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <b-img :src="require('@/assets/images/logo/512.svg')" width="240" height="240" />
        </b-link>

        <b-card-title class="mb-1">
          Adventure starts here 🚀
        </b-card-title>
        <b-card-text class="mb-2">
          Make your app management easy and fun!
        </b-card-text>

        <!-- form -->
        <validation-observer ref="registerForm">
          <b-form
            class="auth-register-form mt-2"
            @submit.prevent="validationForm"
          >
            <!-- username -->
            <b-form-group
              label="Your Email"
              label-for="email"
            >
              <validation-provider
                #default="{ errors }"
                name="email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="email"
                  :state="errors.length > 0 ? false:null"
                  name="register-email"
                  placeholder=""
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- username -->
            <b-form-group
              label="Your Username"
              label-for="username"
            >
              <validation-provider
                #default="{ errors }"
                name="username"
                :rules="{required: true,  regex: /^[a-z0-9]+$/}"
                :custom-messages="{regex: 'Username can only contain lowercase characters and numbers'}"
              >
                <b-form-input
                  id="username"
                  v-model="username"
                  :state="errors.length > 0 ? false:null"
                  name="username"
                  placeholder=""
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              label="Your FB ID"
              label-for="register-fb"
            >
              <validation-provider
                #default="{ errors }"
                name="register-fb"
                rules="required"
              >
                <b-form-input
                  id="fbid"
                  v-model="facebook"
                  :state="errors.length > 0 ? false:null"
                  name="register-fb"
                  placeholder=""
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- name -->
            <b-form-group
              label="Your full name"
              label-for="register-name"
            >
              <validation-provider
                #default="{ errors }"
                name="name"
                rules="required"
              >
                
                <b-form-input
                  id="name"
                  v-model="name"
                  :state="errors.length > 0 ? false:null"
                  name="register-name"
                  placeholder=""
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group
              label="Password"
              label-for="a-password"
            >
              <validation-provider
                #default="{ errors }"
                name="Password"
                id="Password"
                rules="required|min:8"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="a-password"
                    v-model="password"
                    :state="errors.length > 0 ? false:null"
                    type="password"
                    placeholder="Password"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
              <h5 class="center">By clicking "Sign Up" you agree to <a href="#">Terms & Conditions</a> and <a href="#">Privacy Policy .</a></h5>
            </b-form-group>

            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid"
            >
            <b-spinner v-if="processing"
              small
            />
              Sign up
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <span>Already have an account? </span>
          <b-link :to="{name:'login'}">
            <span>Sign in instead</span>
          </b-link>
        </b-card-text>
      </b-card>
    <!-- /Register v1 -->
    </div>
  </div>

</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BLink, BCardTitle, BCardText, BForm,
  BImg, BButton, BFormInput, BFormGroup, BInputGroup, BInputGroupAppend, BFormCheckbox, BSpinner
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    VuexyLogo,
    // BSV
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    // validations
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    BImg
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      email: '',
      username: '',
      name: '',
      facebook: '',
      phone:'',
      password: '',
      retypepassword: '',
      repeat_password:'',
      status: false,
      processing: false,
      invalid: false,
      // validation rules
      validation:{
        required,
        email,
      }
      
    }
  },

  created(){
    
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    validationForm() {
     
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          this.processing = true;
          this.invalid = false
          this.$store.dispatch('auth/register',{
            username: this.username,
            email: this.email,
            password: this.password,
            phone: this.phone,
            facebook: this.facebook,
            name: this.name,
            refid: this.$router.currentRoute.query.r || ""
          }).then(rs=>{
            console.log(rs)
            if(rs.success){
              this.$gtag.event('signup', {method: 'email'})
              this.$swal({
                title: 'Success',
                html: `Register Success!<br>Please Login to continue<br>
                  If you have any question, Please contact us.</br>
                  <a href="https://www.facebook.com/messages/t/fastproxy.vip" target="_blank">Chat Facebook</a> or <a href="skype:support@fastproxy.vip?chat" class="fa fa-facebook">Chat Skype</a></br>
                  Đăng ký thành công</br>Vui lòng đăng nhập để tiếp tục sử dụng dịch vụ</br>
                  Nếu có bất kì câu hỏi nào, Hãy liên hệ với chúng tôi</br>
                  <a href="https://www.facebook.com/messages/t/fastproxy.vip" target="_blank">Chat Facebook</a> hoặc <a href="skype:support@fastproxy.vip?chat" class="fa fa-facebook">Chat Skype</a></br>
                  <a href="https://www.facebook.com/messages/t/fastproxy.vip" target="_blank"><button type="button" class="swal2-confirm btn btn-primary" aria-label="" style="display: inline-block;">Chat Facebook</button></a>
                  <a href="skype:support@fastproxy.vip?chat" target="_blank"><button type="button" class="swal2-confirm btn btn-primary" aria-label="" style="display: inline-block;">Chat Skype</button></a>
                `,
                icon: 'success',
                confirmButtonText: 'Login',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              }).then(rs=>{
                this.$router.push('/login')
              })

              // this.$swal({
              //   title: 'Success',
              //   html: `We have received your registration information and send email to active account.</br>Please check your email or contact us:</br>
              //   <a href="https://www.facebook.com/messages/t/fastproxy.vip" target="_blank">Chat Facebook</a> or <a href="skype:support@fastproxy.vip?chat" class="fa fa-facebook">Chat Skype</a></br>
              //     We will quickly verify and activate your account.</br>
              //     Chúng tôi đã nhận được thông tin đăng ký của bạn và gửi cho bạn một email để kích hoạt tài khoản</br>Vui lòng kiểm tra email của bạn hoặc liên hệ với chúng tôi</br>
              //     <a href="https://www.facebook.com/messages/t/fastproxy.vip" target="_blank">Chat Facebook</a> hoặc <a href="skype:support@fastproxy.vip?chat" class="fa fa-facebook">Chat Skype</a></br>
              //     Chúng tôi sẽ xác thực và kích hoạt tài khoản cho bạn một cách nhanh chóng</br>
              //     <a href="https://www.facebook.com/messages/t/fastproxy.vip" target="_blank"><button type="button" class="swal2-confirm btn btn-primary" aria-label="" style="display: inline-block;">Chat Facebook</button></a>
              //     <a href="skype:support@fastproxy.vip?chat" target="_blank"><button type="button" class="swal2-confirm btn btn-primary" aria-label="" style="display: inline-block;">Chat Skype</button></a>

              //   `,
              //   icon: 'success',
              //   confirmButtonText: 'Check your email',
              //   customClass: {
              //     confirmButton: 'btn btn-primary',
              //   },
              //   buttonsStyling: false,
              // }).then(rs=>{
              //   this.$router.push('/login')
              // })
            }else{
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Register Error: '+rs.msg,
                  icon: 'AlertOctagonIcon',
                  variant: 'danger',
                },
              });
              this.processing = false;
              this.invalid = false
            }
            
        }).catch(e => {
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Register Error: '+e.message,
                icon: 'AlertOctagonIcon',
                variant: 'danger',
              },
          });
          this.processing = false;
          this.invalid = false
        })
      } 
    })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

</style>
