export default [
  // Users
  {
    title: 'Dasboard',
    icon: 'HomeIcon',
    route: 'home',
    role: 1,
  },
  {
    title: 'Pricing',
    icon: 'DollarSignIcon',
    route: 'pricing',
    role: 1,
  },

  {
    title: 'Add Funds',
    icon: 'DollarSignIcon',
    route: 'add-funds',
    role: 1,
  },
  
  {
    title: 'Buy & Billing',
    icon: 'CreditCardIcon',
    route: 'billing',
    role: 1,
  },
  
  {
    title: 'List Proxies',
    icon: 'RssIcon',
    route: 'list-proxies',
    role: 1,
  },
  {
    title: 'IP Use BW',
    icon: 'ActivityIcon',
    route: 'ip-use-bw',
    role: 1,
  },
  // {
  //   title: 'List Ports',
  //   icon: 'ActivityIcon',
  //   route: 'list-ports',
  //   role: 1,
  // },

  // {
  //   title: 'Log Ports',
  //   icon: 'FileIcon',
  //   route: 'log-ports',
  //   role: 1,
  // },

  // {
  //   title: 'Pass RegExp',
  //   icon: 'ActivityIcon',
  //   route: 'bypass-domain',
  //   role: 1,
  // },
  // {
  //   title: 'Order',
  //   icon: 'ShoppingCartIcon',
  //   route: 'order',
  //   role: 1,
  // },
  {
    title: 'Info & API',
    icon: 'CodeIcon',
    route: 'api-documents',
    role: 1,
  },
  
  {
    title: 'Terms of Service',
    icon: 'BookIcon',
    route: 'term',
    role: 1,
  },
  // {
  //   title: 'Support',
  //   icon: 'HeadphonesIcon',
  //   route: 'support',
  //   role: 1,
  // },
  // Admin
  {
    title: 'Dashboard',
    icon: 'HomeIcon',
    route: 'apanel',
    role: 0,
  },
  // {
  //   title: 'Analytics',
  //   icon: 'ActivityIcon',
  //   route: 'analytics',
  //   role: 0,
  // },
  {
    title: 'Proxy Manager',
    icon: 'HomeIcon',
    route: 'list-proxy',
    role: 0,
  },
  {
    title: 'Users Manager',
    icon: 'UsersIcon',
    route: 'users-manager',
    role: 0,
  },
  {
    title: 'Settings',
    icon: 'SettingsIcon',
    route: 'settings',
    role: 0,
  },
  {
    title: 'List Proxies',
    icon: 'RssIcon',
    route: 'member-manager',
    role: 2,
  },
  {
    title: 'API',
    icon: 'CodeIcon',
    route: 'member-api',
    role: 2,
  },
  {
    title: 'Terms of Service',
    icon: 'BookIcon',
    route: 'term-m',
    role: 2,
  },

]
