<template>
  <div style="max-width: 100rem; margin: auto;">
    
    <b-card style="height: 30rem;">
      <b-card-header class="pb-50">
        <b-card-title>White IP</b-card-title>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Line by line IP addresses, If white ip is empty, all ip will be accepted"
              label-for="h-first-name">
              <b-form-textarea
              v-model="wips"
              rows="10"
              placeholder="White ip"></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-button variant="primary" :disabled="onproceess" @click="onUpdateWhiteIP">Save</b-button>
          </b-col>
        </b-row>
        
      </b-card-body>
    </b-card>

    <b-card>
      <b-card-header class="pb-50">
        <b-card-title>IP Use Bandwidth</b-card-title>
      </b-card-header>
      <b-card-body>
        <h5>This is data received indirectly from the Proxy Server to the Website, so data may be missing due to errors in receiving each bandwidth addition.</h5>
      </b-card-body>
      <b-card-body>
      <div class="d-flex justify-content-between  flex-wrap">

        <!-- sorting  -->
        <b-form-group
          label="Sort"
          label-size="sm"
          label-align-sm="left"
          label-cols-sm="2"
          label-for="sortBySelect"
          class="mr-1 mb-md-0"
        >
          <b-input-group
            size="sm"
          >
            <b-form-select
              id="sortBySelect"
              v-model="sortBy"
              :options="sortOptions"
            >
              <template #first>
                <option value="">
                  none
                </option>
              </template>
            </b-form-select>
            <b-form-select
              v-model="sortDesc"
              size="sm"
              :disabled="!sortBy"
            >
              <option :value="false">
                Asc
              </option>
              <option :value="true">
                Desc
              </option>
            </b-form-select>
          </b-input-group>
        </b-form-group>
        <!-- date time select -->
        <b-form-group
          label="Select Date"
          label-size="sm"
          label-align-sm="left"
          label-cols-sm="3"
          label-for="dateRange"
          class="mr-1 mb-md-0"
        >
          <b-input-group size="sm">
            <b-form-datepicker
              id="dateRange"
              v-model="dateRange"
              :start-weekday="1"
              :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
              class="mr-1"
            />
            <b-button
              variant="outline-secondary"
              size="sm"
              @click="onSelectDate"
            >
              Search
            </b-button>
          </b-input-group>
        </b-form-group>
        <!-- filter -->
        <!-- <b-form-group
          label="Filter"
          label-cols-sm="2"
          label-align-sm="left"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />
            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
              >
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group> -->
      </div>
    </b-card-body>
    <b-table
                striped
                hover
                responsive
                class="position-relative"
                :per-page="perPage"
                :current-page="currentPage"
                :items="ipusebw"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
        >
        <template #cell(ip)="data">
          <b-badge
            :variant="checkValidIp(data.item.ip) == true  ? 'light-success' : 'light-danger'"
            class="text-capitalize"
          >
            {{ data.item.ip}}
          </b-badge>
        </template>
      </b-table> 

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

<!-- page length -->
<b-form-group
  label="Per Page"
  label-cols="6"
  label-align="left"
  label-size="sm"
  label-for="sortBySelect"
  class="text-nowrap mb-md-0 mr-1"
>
  <b-form-select
    id="perPageSelect"
    v-model="perPage"
    size="sm"
    inline
    :options="pageOptions"
  />
</b-form-group>

<!-- pagination -->
<div>
  <b-pagination
    v-model="currentPage"
    :total-rows="totalRows"
    :per-page="perPage"
    first-number
    last-number
    prev-class="prev-item"
    next-class="next-item"
    class="mb-0"
  >
    <template #prev-text>
      <feather-icon
        icon="ChevronLeftIcon"
        size="18"
      />
    </template>
    <template #next-text>
      <feather-icon
        icon="ChevronRightIcon"
        size="18"
      />
    </template>
  </b-pagination>
</div>
</b-card-body>
    </b-card>
  </div>
</template>

<script>
import { BCard, BCardText,BTab,BTabs, BFormTextarea, BRow,BCol,BForm,BFormInput, BFormGroup, BInputGroupAppend,  BCardTitle,
  BButton, BInputGroup,BCardHeader, BCardBody, BTable,BBadge, BFormSelect,BPagination, BFormDatepicker
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

import RepositoryFactory from '../../api/RepositoryFactory'
const UsersRepository = RepositoryFactory.get('user')
const PLAN = RepositoryFactory.get('plans')
const NPORT = RepositoryFactory.get('nport')

export default {
  components: {
    BCard,
    BCardText,
    BCardTitle,
    BCardHeader,
    BCardBody,
    BTabs,
    BTab,BFormTextarea,
    VuePerfectScrollbar,
    AppCollapse,
    AppCollapseItem,
    BBadge,
    BRow,BCol,BForm,BFormInput, BFormGroup, BInputGroupAppend,
    BButton,BInputGroup,
    BTable,BFormSelect,BPagination,BFormDatepicker
  },
  data(){
    return{

      wips:"",
      onproceess: false,
      ipusebw: [],
      fields: [
        { key: 'ip', label: 'IP', sortable: true },
        { key: 'totalbw', label: 'Total Bandwidth', sortable: true },
      ],
      perPage: 20,
      pageOptions: [5, 10, 20, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: 'created_at',
      sortDesc: true,
      sortDirection: 'desc',
      filter: null,
      filterOn: [],
      
    }
  },
  created(){
    //console.log(this.$store.getters['auth/userInfo'])
    //this.$gtag.event('route', {state: 'api-page'})
    NPORT.getWhiteIP().then(rs=>{
      this.wips = rs.data.data.filter(x=>x != "").join('\n')
    })
    const currentDate = new Date();
    const formattedDate = `${currentDate.getMonth() + 1}-${currentDate.getDate()}-${currentDate.getFullYear()}`;
    this.dateRange = formattedDate
    PLAN.getIPUseBW(formattedDate).then(rs=>{

      this.ipusebw = rs.data.data
    })

    // this.ipusebw = [
    //   {
    //       "ip": "14.243.47.14",
    //       "totalbw": "28.49 KB"
    //   },
    //   {
    //       "ip": "14.243.47.15",
    //       "totalbw": "28.49 KB"
    //   }
    // ]
  },
  setup(){
    const perfectScrollbarSettings = {
      maxScrollbarLength: 120,
      wheelPropagation: false,
      suppressScrollY: true,
      useBothWheelAxes: true
    }
    

    return {
      perfectScrollbarSettings,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  methods: {

    async onUpdateWhiteIP(){
      this.onproceess = true
      let arr = this.wips.split('\n').filter(x => x != "")

      //Check arr element is valid ip
      let check = true
      arr.forEach(ip=>{
        if(!ip.match(/^(\d{1,3}\.){3}\d{1,3}$/)){
          check = false
        }
      })

      if (check){
        NPORT.updateWhiteIP({ips: arr}).then(rs=>{
          if(rs.data.success){
            this.$toast.success('Update white ip success')
          }
        })
      }else{
       
        this.$toast.error('IP format is invalid')
      }
      this.onproceess = false
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    checkValidIp(ip){
      if(this.wips == "") return true

      if(this.wips.includes(ip)) return true
      return false
    },
    onSelectDate(){
      const today = new Date();
      const selectedDate = new Date(this.dateRange);
      const diffTime = today - selectedDate;
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
      if (diffDays > 7) {
        this.$toast.error('Selected date cannot be greater than 7 days from today.');
        return;
      }

      if(diffDays <= 0){
        this.$toast.error('Selected date cannot be greater than today.');
        return;
      }

      // Proceed with your logic if the date is within the range
      let slDate = new Date(this.dateRange)
      let queryDate = `${slDate.getMonth() + 1}-${slDate.getDate()}-${slDate.getFullYear()}`
      PLAN.getIPUseBW(queryDate).then(rs=>{
        if(rs.response && rs.response.status === 429){
          this.$toast.error('Too many requests. Please try again later.');
        }
        if(rs.data.success){
        console.log('update')
          this.ipusebw = rs.data.data
        }else{
          this.$toast.error(rs.data.errMsg)
        }
      })
    }
  }
}
</script>

<style>
  .dlborder{
    border-bottom: 1px solid #e9eff5;
  }
  .code{
    overflow-x: auto;
    border-radius: .4rem;
    background-color: #f8fbfd;
    border: 1px solid #e9eff5;
  }
</style>
