<template>
  <b-row>
    <b-col cols="12">
      <b-card>
        <b-card-header class="pb-50">
          <b-card-title>Proxy List</b-card-title>
          <!-- <b-card-actions>
            
          </b-card-actions> -->
          <div class="demo-inline-spacing">
              <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'"  variant="outline-primary" @click="showModal(null)">
                  Create Port
                </b-button>
                <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-b-toggle.collapse-1 variant="outline-success">
                    Settings
                </b-button>
                <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-success" @click="onExport">
                    Export
                </b-button>

                <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-success" @click="onExportAuth(null)">
                    Export Auth
                </b-button>
                <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-success" @click="onExportAuth(true)">
                    Export Auth Old
                </b-button>
                <!-- <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-success" @click="onExportWIP">
                    Export WIP
                </b-button> -->
                <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-danger" @click="onHidePort" :disabled="busy">
                  Delete
                </b-button>
            </div>
        </b-card-header>
        
        <b-card-body>
          <b-collapse id="collapse-1" class="mt-2">
              <b-card class="border mb-0">
                <b-card-text class="card-text">
                  Apply setting for: <b-badge pill variant="success" v-for="a in select_port" :key="a">{{a}}</b-badge>
                </b-card-text>

                <b-card-body>
                  <b-row>
                    <b-col cols="6">
                      <b-form-group
                        label="Key Port"
                        label-for="h-first-name"
                        label-cols-md="3"
                      >
                        <b-input-group>
                          <b-form-input
                            id="h-first-name"
                            placeholder="Name"
                            v-model="settings.keyport"
                          />
                          <b-input-group-append>
                            <b-button variant="outline-primary" @click="onChangeKeyPort">
                              Change
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>
                        
                      </b-form-group>
                    </b-col>
                    <b-col cols="6" class="mb-1">
                      <b-form-group
                        label="Geo"
                        label-for="h-first-name"
                        label-cols-md="3"
                      >
                        <b-input-group>
                          <b-form-select
                            v-model="settings.country"
                            :options="country"
                            @change="changeCtSett(settings.ctsett,settings.country)"
                          />
                          <b-form-select
                            v-model="settings.ctsett"
                            :options="listgeo"
                            @change="changeCtSett(settings.ctsett,settings.country)"
                          />

                          <b-form-select
                            v-if= "settings.ctsett == 1"
                            v-model="settings.state"
                            :options="lstate"
                          />

                          <b-form-select
                            v-else-if= "settings.ctsett == 2"
                            v-model="settings.city"
                            :options="lcity"
                          />


                          <!-- <b-input-group-append>
                            
                            <b-button variant="outline-primary" @click="onChangeGeo">
                              Change
                            </b-button>
                          </b-input-group-append> -->
                        </b-input-group>
                        
                        
                      </b-form-group>
                    </b-col>

                    <b-col cols="6">
                      <b-form-group
                        label="Rotate"
                        label-for="h-first-name"
                        label-cols-md="3"
                      >
                        <b-input-group>
                          <b-form-spinbutton
                            v-model="settings.rotate"
                            min="1"
                            max="360"
                          />
                          <!-- <b-input-group-append>
                            <b-button variant="outline-primary" @click="onChangeRoatate">
                              Change
                            </b-button>
                          </b-input-group-append> -->
                        </b-input-group>
                        
                      </b-form-group>
                    </b-col>
                  
                    <!-- <b-col cols="6">
                      <b-form-group
                        label="Type"
                        label-for="h-first-name"
                        label-cols-md="3"
                      >
                        <b-form-select
                          v-model="settings.proxy_type"
                          :options="proxy_type"
                        />
                      </b-form-group>
                    </b-col> -->

                    <!-- <b-col cols="6">
                      <b-form-group
                        label="Services"
                        label-for="h-first-name"
                        label-cols-md="3"
                      >
                        <b-row>
                          <b-col cols="10">
                            <b-badge  v-for="data in settings.services" :key="data.name">{{data.name}}</b-badge>
                            <b-badge
                            :variant="data.bypass ? 'warning' : 'success'"
                              v-for="data in tempPost.services" 
                              :key="data.name"
                            >
                              <span>{{data.name}}</span>
                            </b-badge>
                          </b-col>
                          <b-col cols=2>
                            <b-button
                              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                              variant="outline-primary"
                              class="btn-icon"
                              @click="showModalServices"
                            >
                              <feather-icon icon="PlusIcon" />
                            </b-button>
                            
                          </b-col>
                        </b-row>
                      </b-form-group>
                    </b-col> -->

                    <!-- <b-col cols="6">
                      <b-form-group
                        label="Pass RegExp"
                        label-for="h-first-name"
                        label-cols-md="3"
                      >
                        <b-input-group>
                          <b-form-select
                            v-model="settings.group_id"
                            :options="groups"
                            text-field="show"
                            value-field="_id"
                          >
                          
                          </b-form-select>

                        </b-input-group>
                        
                      </b-form-group>
                    </b-col> -->

                    <b-col cols="6">
                      <b-form-group
                        label="Port Status"
                        label-for="h-first-name"
                        label-cols-md="3"
                      >
                      <div class="demo-inline-spacing">
                        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-success" @click="onStatusChange(true)">
                            Enable Status
                        </b-button>
                        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-danger" @click="onStatusChange(false)">
                            Disable Status
                        </b-button>
                      </div>
                      </b-form-group>
                    </b-col>

                    <b-col cols="12">
                      <div class="demo-inline-spacing">
                        <b-overlay
                          :show="setting_busy"
                          rounded
                          opacity="0.6"
                          spinner-small
                          spinner-variant="primary"
                          class="d-inline-block"
                        >
                          <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-success" :disabled="setting_busy" @click="changeSetting()">
                            Apply Settings
                          </b-button>
                        </b-overlay>

                        
                      </div>
                    </b-col>

                    <!-- <b-col cols="6">
                      <b-form-group
                        label="Status"
                        label-for="h-first-name"
                        label-cols-md="3"
                      >
                        <b-form-checkbox
                          v-model="settings.status"
                          class="custom-control-primary"
                          name="check-button"
                          switch
                          @change="onStatusChange"
                        />
                      </b-form-group>
                    </b-col> -->
                  </b-row>
                </b-card-body>
                <!-- <b-card-footer>
                  <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" @click="onApply()">
                    Apply
                  </b-button>
                </b-card-footer> -->
              </b-card>
          </b-collapse>
        </b-card-body>

        <b-card-body>
          <div class="d-flex justify-content-between  flex-wrap">

            <div class="demo-inline-spacing">
              <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'"  variant="outline-primary" @click="selectAll()">
                Select All On Page
              </b-button>
              <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'"  variant="outline-primary" @click="clearSelect()">
                Clear Select
              </b-button> 
              <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'"  variant="outline-primary" @click="changeIP()">
                Change IP
              </b-button>
              <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'"  variant="outline-primary" @click="changePort">
                Change Port
              </b-button>
              <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'"  variant="outline-primary" @click="exportSession">
                Export Session
              </b-button>
              <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'"  variant="outline-primary" @click="exportSessionOld">
                Export Session Old
              </b-button>
              <!-- <b-form-checkbox  @change="onSortMode">Sort by WIP</b-form-checkbox> -->
          </div>
            <!-- sorting 
            <b-form-group
              label="Sort"
              label-size="sm"
              label-align-sm="left"
              label-cols-sm="2"
              label-for="sortBySelect"
              class="mr-1 mb-md-0"
            >
              <b-input-group
                size="sm"
              >
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                >
                  <template #first>
                    <option value="">
                      none
                    </option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  :disabled="!sortBy"
                >
                  <option :value="false">
                    Asc
                  </option>
                  <option :value="true">
                    Desc
                  </option>
                </b-form-select>
              </b-input-group>
            </b-form-group> -->

            <!-- filter -->
            <b-form-group
              label="Filter"
              label-cols-sm="2"
              label-align-sm="left"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                  @input="isEmptyHandle"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="onChangeText"
                    
                  >
                    Search
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
        </b-card-body>
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- page length -->
          <div>
            <b-form-group
              label="Per Page"
              label-cols="6"
              label-align="left"
              label-size="sm"
              label-for="sortBySelect"
              class="text-nowrap mb-md-0 mr-1"
            >
              <b-form-select
                v-if="!custom_mode"
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                inline
                :options="pageOptions"
                @change="selectPerpage"
                > 
              </b-form-select>
              <b-input-group v-else>
                <b-form-input 
                  size="sm"
                  v-model="perPage"
                ></b-form-input>
                <b-input-group-append>
                  <b-button
                    size="sm"
                    variant="outline-primary"
                    @click="custom_mode = false"
                  >
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
          <!-- pagination -->
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
        <b-table
          striped
          hover
          ref="table"
          responsive
          class="position-relative"
          :per-page="perPage"
          :current-page="currentPage"
          :items="proxies"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
          :sort-compare="sortingChanged"
          :busy="table_busy"
        >

          <template #cell(select)="data">
            <b-form-checkbox  v-model="data.item.selected" @change="onSelected"></b-form-checkbox>
          </template>
          <template #cell(expand)="data">
            <b-form-checkbox  v-model="data.item.selected" @change="onSelected"></b-form-checkbox>
          </template>
          <template #cell(services)="data" width="100px">

            <!-- <b-badge :variant="s.bypass ? 'success' : 'warning'" v-for="s in data.item.services" :key="s.name">{{s.name}}</b-badge> -->
            <div style="height:50px; width: 100%; white-space: nowrap;">
              <b-badge
                :variant="data.bypass ? 'warning' : 'success'"
                v-for="data in data.item.services" 
                :key="data.name"
              >
                <!-- <feather-icon
                  :icon="data.bypass ?  'CheckIcon' : ''"
                  class="mr-25"
                /> -->
                <span>{{data.name}}</span>
              </b-badge>
            </div>
            
          </template>

          <template #cell(rotate)="data">
            <b-badge variant="success">{{data.item.rotate}} minutes</b-badge>
          </template>

          <template #cell(status)="data">
            <b-badge :variant="data.item.status ? 'success' : 'danger'">{{ data.item.status ? 'On' : 'Off'}}</b-badge>
          </template>

          <template #cell(bandwidth)="data">
            {{formatBytes(data.item.bandwidth)}}
          </template>
          <template #cell(bandwidth_save)="data">
            {{formatBytes(data.item.bandwidth_save)}}
          </template>


          <template #cell(country)="data">
            <b-badge variant="success">{{ data.item.country}}</b-badge>
          </template>

          <template #cell(ctsett)="data">
            <b-badge v-if="data.item.ctsett==1" variant="success">State {{ data.item.state}}</b-badge>
            <b-badge v-else-if="data.item.ctsett==2" variant="success">City: {{ data.item.city}}</b-badge>
            <b-badge v-else variant="success">All</b-badge>
          </template>

          <template #cell(wipport)="data">
            <div>
              {{data.item.wip && data.item.wip.used ? data.item.wip.host+':'+data.item.wip.port : 'Not used'}}
              {{data.item.wip && data.item.wip.used ? getDate(new Date(data.item.wip.date_expired)) : ''}}
              <b-dropdown
                v-if="data.item.wip && data.item.wip.used"
                variant="flat-primary"
                no-caret
                class="chart-dropdown"
                toggle-class="p-0"
                right
              >
                <template #button-content>
                  <feather-icon
                    icon="ChevronDownIcon"
                    size="18"
                    class="text-body cursor-pointer"
                  />
                </template>
                <b-dropdown-item v-if="data.item.wip && !data.item.wip.log_enable" @click="enableLog(data.item, true)">
                  Enable Log
                </b-dropdown-item>
                <b-dropdown-item v-else @click="enableLog(data.item,false)">
                  Disable Log
                </b-dropdown-item>
                <b-dropdown-item @click="renewWip(data.item)">
                  Renew
                </b-dropdown-item>
                <b-dropdown-item @click="deleteWip(data.item)">
                  Delete
                </b-dropdown-item>
              </b-dropdown>
            </div>
            
          </template>

          <template #cell(_id)=data>
            <div class="text-nowrap">
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="flat-success"
                class="btn-icon rounded-circle"
                v-b-tooltip.hover.top="'Change Ip'"
                @click="changeIp(data.item)"
              >
                <feather-icon icon="RotateCwIcon" />
              </b-button>
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="flat-warning"
                class="btn-icon rounded-circle"
                v-b-tooltip.hover.top="'Clone'"
                @click="clonePort(data.item)"
              >
                
                <feather-icon icon="CopyIcon" />
              </b-button>
              
              <!-- <b-button
                v-if="!data.item.wip || (data.item.wip && !data.item.wip.used)"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="flat-primary"
                class="btn-icon rounded-circle"
                v-b-tooltip.hover.top="'Create WIP Port'"
                @click="createWip(data.item)"
              >
                <feather-icon icon="SettingsIcon" />
              </b-button> -->

              
            </div>
            <div class="text-nowrap">
            
            </div>
          </template>
          <template #cell(group_id)=data>
            {{ getGroupName(data.item.group_id)}}
          </template>
        </b-table>

        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- page length -->
          <div>
            <b-form-group
              label="Per Page"
              label-cols="6"
              label-align="left"
              label-size="sm"
              label-for="sortBySelect"
              class="text-nowrap mb-md-0 mr-1"
            >
              <b-form-select
                v-if="!custom_mode"
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                inline
                :options="pageOptions"
                @change="selectPerpage"
                > 
              </b-form-select>
              <b-input-group v-else>
                <b-form-input 
                  size="sm"
                  v-model="perPage"
                ></b-form-input>
                <b-input-group-append>
                  <b-button
                    size="sm"
                    variant="outline-primary"
                    @click="custom_mode = false"
                  >
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
          <!-- pagination -->
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
        <b-modal
          id="modal-edit-account"
          cancel-title="Cancel"
          size="lg"
          title="Create Port"
          no-close-on-backdrop
        >
          <b-form @submit.prevent>
            <b-row>

              <b-col cols="12">
                <b-form-group
                  label="Port"
                  label-for="h-first-name"
                  label-cols-md="3"
                  
                >
                  <b-input-group>
                  <b-form-input
                    id="input-live"
                    placeholder="Port"
                    type="number"
                    :disabled="custom_port"
                    v-model="tempPost.port"
                    :state="onChangePort"
                    aria-describedby="input-live-feedback"
                  />
                  
                  <b-input-group-append>
                    <b-button variant="outline-primary" @click="custom_port=!custom_port">
                      Custom
                    </b-button>
                  </b-input-group-append>
                  <b-form-invalid-feedback id="input-live-feedback">
                    Port {{ tempPost.port }} is not availble
                  </b-form-invalid-feedback>
                </b-input-group>
               
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Key Port"
                  label-for="h-first-name"
                  label-cols-md="3"
                >
                  <b-input-group>
                  <b-form-input
                    id="h-first-name"
                    placeholder="API Key"
                    disabled
                    v-model="tempPost.keyport"
                  />
                  <b-input-group-append>
                    <b-button variant="outline-primary" @click="onRandomKeyport">
                      Random
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group
                  label="Geo"
                  label-for="h-first-name"
                  label-cols-md="3"
                >
                  <b-input-group>
                    <b-form-select
                      v-model="tempPost.country"
                      :options="country"
                      @change="changeCtSett(tempPost.ctsett,tempPost.country)"
                    />
                    <b-form-select
                      v-model="tempPost.ctsett"
                      :options="listgeo"
                      @change="changeCtSett(tempPost.ctsett,tempPost.country)"
                    />

                    <b-form-select
                      v-if= "tempPost.ctsett == 1"
                      v-model="tempPost.state"
                      :options="lstate"
                    />

                    <b-form-select
                      v-else-if= "tempPost.ctsett == 2"
                      v-model="tempPost.city"
                      :options="lcity"
                    />                    
                  </b-input-group> 
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Rotate"
                  label-for="h-first-name"
                  label-cols-md="3"
                >
                  <b-form-spinbutton
                    v-model="tempPost.rotate"
                    min="1"
                    max="360"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
          <div slot="modal-footer" class="demo-inline-spacing">
            <b-button
              @click="$bvModal.hide('modal-edit-account');"
            >Cancel</b-button>
            
            <b-button
              variant="success"
              @click="onSubmit"
              :disabled="busy"
            >
              <b-spinner small type="grow" v-show="busy"></b-spinner>
              Create
            </b-button>
          </div>
        </b-modal>
        <b-modal
          id="modal-services"
          ok-title="Done"
          cancel-title="Close"
          size="lg"
          title="Select Services"
          @ok="onSVSubmit"
          @abort="abortVS"
          no-close-on-backdrop
        >
          <b-row>
              <b-col cols="12">
                <b-form-group
                  label="Services"
                  label-for="h-first-name"
                  label-cols-md="3"
                >
                  <b-row v-for="data in services" :key="data.name">
                    <b-col cols="6">
                      <b-form-checkbox  v-model="data.selected">{{data.name}}</b-form-checkbox>
                    </b-col>
                    <b-col cols="6">
                      <b-form-checkbox  v-model="data.bypass"  :disabled="!data.selected">Use Proxy</b-form-checkbox>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>
          </b-row>
        </b-modal>
        <b-modal
          id="modal-clone"
          cancel-title="Cancel"
          size="lg"
          title="Clone Port"
          no-close-on-backdrop
          centered
        >
          <b-row>
            <b-col cols="12">
              <b-form-group
                  label="Select Mode"
                  label-for="h-first-name"
                  label-cols-md="3"
                  
                >
                  <b-form-radio-group
                    id="radio-group-2"
                    name="radio-sub-component"
                  >
                    <b-form-radio value="1">Clone Single Port</b-form-radio>
                    <b-form-radio value="2">Clone Multiple Custom</b-form-radio>
                    <b-form-radio value="3">Clone By Range</b-form-radio>
                  </b-form-radio-group>
                </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                  label="Port"
                  label-for="h-first-name"
                  label-cols-md="3"
                  
                >
                  <b-form-input
                    type="number"
                  >

                  </b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                  label="Port"
                  label-for="h-first-name"
                  label-cols-md="3"
                  
                >
                  <b-row>
                    <b-col cols="4">
                      <b-form-input
                        type="number"
                      ></b-form-input>
                    </b-col>
                    <b-col cols="4">
                      <b-form-input
                        type="number"
                      ></b-form-input>
                    </b-col>
                  </b-row>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                  label="Progress"
                  label-for="h-first-name"
                  label-cols-md="3"
                  
                >
                  <b-progress :max="100" height="1rem" variant="success">
                    <b-progress-bar :value="20">
                      <span>Progress: <strong>{{ 19 }} / {{ 100 }}</strong></span>
                    </b-progress-bar>
                  </b-progress>
              </b-form-group>
            </b-col>
          </b-row>
        </b-modal>
        <b-modal
          id="modal-change-port"
          cancel-title="Cancel"
          size="lg"
          title="Change Port"
          no-close-on-backdrop
          centered
        >
          <b-row>
            <b-col cols="12" v-for="item in ports_change" v-bind:key="item._id">
              <b-row>
                <b-col cols="6">
                  <b-form-group
                    label="Port"
                    label-for="h-first-name"
                    label-cols-md="3"
                  >
                    <b-form-input
                      type="number"
                      v-model="item.port"
                      disabled
                    >

                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                    <b-form-group
                      label="change to"
                      label-for="h-first-name"
                      label-cols-md="3"
                      
                    >
                      <b-form-input
                        type="number"
                        v-model="item.new_port"
                        :state="watchChangePort(item._id,item.new_port)"
                      >

                      </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <div slot="modal-footer" class="demo-inline-spacing">
            <b-button
              @click="$bvModal.hide('modal-change-port');"
            >Cancel</b-button>
            
            <b-button
              variant="success"
              @click="onChangePortName"
              :disabled="busy"
            >
              <b-spinner small type="grow" v-show="busy"></b-spinner>
              Change
            </b-button>
          </div>

        </b-modal>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BTable, BAvatar, BBadge, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BCardBody, BOverlay, BCard, BTooltip, BCardHeader, BCardTitle, BCol,BRow, BForm,
  BFormTextarea,BFormCheckbox,BFormSpinbutton ,BCollapse, VBToggle, BCardFooter, BCardText, VBTooltip, BFormRadioGroup, BDropdown, BDropdownItem, BListGroup, BListGroupItem, BAlert, BFormSelectOption, BFormInvalidFeedback, BSpinner,
  BProgress,BProgressBar, BFormRadio
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import RepositoryFactory from '../../../api/RepositoryFactory'
let NPORT = RepositoryFactory.get('nport')
let DOMAIN = RepositoryFactory.get('domain')



import services_ from '../../../assets/data/services'
//import targetCity from '../../../assets/data/targetCity'
//import targetState from '../../../assets/data/targetState'
import crypto from 'crypto'

const base_service = Object.assign([],services_)
const tempObj = Object.freeze({
  "_id": null,
  "port": 10001,
  "nameport": "",
  "keyport": "",
  "country": "US",
  "rotate": 5,
  "proxy_type": "socks",
  "ctsett": 0,
  "state": "",
  "city": "",
  "numport": 10
})

export default {
  components: {
    BCardCode,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BOverlay,
    BCard,
    BTooltip,
    BCardHeader,
    BCardTitle,
    BCol,BRow,
    BForm,
    BFormTextarea,
    BFormCheckbox,
    BFormSpinbutton,
    BCollapse,
    BCardFooter,
    BCardText,
    vSelect,
    BFormRadioGroup,
    BDropdown,
    BDropdownItem,
    VuePerfectScrollbar,
    BListGroup, BListGroupItem,
    BAlert,BFormSelectOption, BFormInvalidFeedback, BSpinner,
    BProgress,BProgressBar, BFormRadio
  },
  data(){
    return {
      proxies: [],
      base_proxies: [],
      
      settings: {
        port: 0,
        keyport: '',
        country: 'ALL',
        rotate: 5,
        proxy_type: 'socks',
        ctsett: 0,
        state: '',
        city: '',
        status: true
      },

      tempPost: Object.assign({},tempObj),
      select_port: [],

      variant: 'dark',
      opacity: 0.85,
      country: this.$store.getters['auth/settings'].geos_avail.sort(),
      blur: '2px',
      perPage: 20,
      pageOptions: ['custom',2,5, 10, 20, 50, 100,200,500,1000],
      totalRows: 1,
      custom_mode: false,
      custom_port: true,
      is_valid_port: true,
      busy: false,

      countryCode: [],
      countryCodeAll: [],
      targetCity: [],
      targetState: [],
      currentPage: 1,
      sortBy: '',
      sortDesc: true,
      sortDirection: 'desc',
      filter: null,
      filterOn: [],
      proxy_type: ['socks','https'],
      services: Object.values(Object.assign({},services_)),
      table_busy: false,
      setting_busy: false,
      fields: [
        'select',
        { key: 'port', label: 'port', sortable: true },
        //{ key: 'wipport', label: 'WIP Port', sortable: true },
        { key: 'keyport', label: 'key port', sortable: true },
        //{ key: 'nameport', label: 'name port', sortable: true },
        { key: 'country', label: 'Country', sortable: true },
        { key: 'ctsett', label: 'Region', sortable: true },
        { key: 'rotate', label: 'rotate', sortable: true },
        //{ key: 'group_id', label: 'Group Bypass', sortable: true },
        { key: 'bandwidth', label: 'Bandwith', sortable: true },
        //{ key: 'bandwidth_save', label: 'Bandwith Save', sortable: true },
        
        'status',
        { key: '_id', label: 'Action' },
        //{ key: 'services', label: 'services', sortable: true },
      ],

      perfectScrollbarSettings: {
        maxScrollbarLength: 10,
        wheelPropagation: false,
      },
      ports_change: [],

      logs_fields:[
        { key: 'time', label: 'Time', sortable: true },
        { key: 'domain', label: 'domain', sortable: true },
        { key: 'bandwidth', label: 'bandwidth', sortable: true },
        { key: 'bypass', label: 'bypass', sortable: true },
      ],
      logs_setting:{
        perPage: 8,
        pageOptions: [8],
        totalRows: 1,
        currentPage: 1,
        sortBy: '',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
      },

      listgeo: [
        {
          "text": "All (Default)",
          value: 0,
        },
        {
          text: "State",
          value: 1,
        },
        {
          text: "City",
          value: 2,
        }
      ],
      selected: "All (Default)",
      //targetState: Object.assign({},targetState),
      lstate: [],
      lcity: [],
      port_view: {},
      lports: [],
      ports_logs: [],
      groups: [],
      scroll_settings: {
        maxScrollbarLength: 120,
        wheelPropagation: false,
        suppressScrollY: true,
        useBothWheelAxes: true
      },
      clone_param:{
        mode: 1,
        port: 10001,

      },
      sort_mode: false

    }
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  created(){
    //console.log(this.country)

    
    this.table_busy = true
    this.$gtag.event('route', {state: 'proxy-manager'})
    NPORT.fetch().then(rs=>{
      this.proxies = rs.data.data.sort((a,b)=>{return parseInt(a.port) - parseInt(b.port)})
        this.base_proxies =  rs.data.data.sort((a,b)=>{return parseInt(a.port) - parseInt(b.port)})
        // this.groups.forEach(x=>{
        //   x.show = x.use_proxy ? "[UseProxy] " +x.name : "[Bypass] " + x.name
        // })
        //this.settings.group_id = this.groups[0]._id

        this.totalRows = this.proxies.length
        this.table_busy = false
    }).catch(e=>{
      this.table_busy = false
    })

    NPORT.getCountryList().then(rs=>{
      this.countryCode = rs.data.data
      this.targetCity = rs.data.cities
      this.targetState = rs.data.states

      //console.log(this.targetState)
    })
    //console.log(this.country)
    this.proxies.forEach(element => {
      element.selected = false
    });

    
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    onChangePort(){
      if(parseInt(this.tempPost.port) < 10001 || parseInt(this.tempPost.port) > 64000 )
        return false
      const ports = this.proxies.map(x=> {return  parseInt(x.port)})
      return !ports.includes(parseInt(this.tempPost.port))

    },


    
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.proxies.length
    this.select_port = this.proxies.filter(x=>x.selected).map(x=> x.port)
  },
  methods: {
    onChangeText(){
      this.proxies = this.base_proxies.filter(x=>
        x.port.includes(this.filter) || x.country.includes(this.filter) ||  x.keyport.includes(this.filter)
      )
      this.totalRows = this.proxies.length
      
    },

    isEmptyHandle(){
      if(this.filter == ""){
        this.proxies = this.base_proxies
        this.totalRows = this.proxies.length
      }
    },
    onSortMode(value){
      if(value){
        this.proxies = this.proxies.sort((a,b)=>{
          return parseInt(a.wip.host.split('.')[0].replace('ip','')) <  parseInt(b.wip.host.replace('ip','')) ? -1 : 1
        })
      }else{
        this.proxies = this.proxies.sort((a,b)=>{
          return parseInt(a.port) <  parseInt(b.port) ? -1 : 1
        })
      }
    },
    selectPerpage(index){
      if(index !== "custom"){
        this.perPage = index
        this.custom_mode = false
      }else{
        this.perPage = 20
        this.custom_mode = true
      }

    },
    watchChangePort(id,port){
      if(parseInt(port) < 10001 || parseInt(port) > 64000 )
        return false
      
      if(this.proxies.map(x=>parseInt(x.port)).includes(parseInt(port)))
        return false

      const temp_ports = this.ports_change.filter(x=>x._id !== id).map(x=>parseInt(x.new_port))
      if(temp_ports.includes(parseInt(port)))
        return false
      
      return true
    },
    generateString(){
      const strs = "qwertyuioplkjhgfdsazxcvbnmQWERTYUIOPLKJHGFDSAZXCVBNM0123456789!@#$%^&*()_+=";
      var str = "";
      for (let index = 0; index < 8; index++) {
          str += strs[Math.floor(Math.random() * strs.length)];
      }
      return str;
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    onSelected(){
      this.select_port = this.proxies.filter(x=>x.selected).map(x=> x.port)
      if(this.select_port.length > 0){

        let data = this.proxies.filter(x=>x.selected)[0]
        //console.log(data)
        this.settings.keyport = data.keyport
        this.settings.country = data.country
        this.settings.rotate = data.rotate
        this.settings.services = data.services
        this.settings.ctsett = data.ctsett ? data.ctsett : 0
        this.settings.state = data.state
        this.settings.city = data.city
        this.changeCtSett(this.settings.ctsett,this.settings.country)
        
      }else{
        this.settings.keyport = ""
        this.settings.country = "US"
        this.settings.rotate = 5
        this.settings.ctsett = 0
      }
    },
    viewLog(p){
      this.port_view = p
      this.ports_logs = p.logs
      this.logs_setting.totalRows = this.ports_logs.length
    },
    getVariant(b){
      if(b >= 50000000)
        return "danger"
      else if(b > 5000000 && b <  50000000 )
        return "warning"
      else
        return "success"
    },  
    showModal(id){
      if(this.base_proxies.length > 2000){
        this.$bvToast.toast("Creat port limit at 2000", {
            title: "Error",
            variant:"danger",
            solid: true,
          })  
          return
      }
      this.tempPost = Object.assign({}, tempObj)
      let index =  Math.random() * (15 - 6) + 6
      this.tempPost.keyport = (+new Date).toString(36).slice(-index)+this.generateString()
      this.tempPost.nameport = (+new Date).toString(36).slice(-6)+'_1'
      this.tempPost.city = this.tempPost.state = ''
      //this.tempPost.group_id = this.groups[0]._id
      this.changeCtSett(0,this.tempPost.country)
      this.custom_port = true
      const ports = this.proxies.map(x=> {return  parseInt(x.port)})

      this.tempPost.port = 10001
      while(true){
        if(!ports.includes( parseInt(this.tempPost.port))){
          break
        }
        this.tempPost.port += 1
      }
      this.is_valid_port = true
      this.services = Object.values({},base_service)
      // this.services.forEach(x=>{
      //   this.tempPost.services.forEach(y=>{
      //     if(x.name === y.name){
      //       x.selected = y.selected
      //       x.bypass = y.bypass
      //     }
      //   })
      // })
      this.$nextTick(()=>{
        this.$bvModal.show('modal-edit-account');
      })
      
    },
    showModalServices(){
      this.services = Object.values(Object.assign({},base_service))
      this.$nextTick(()=>{
        this.services.forEach(x=>{
          x.selected = false
          x.bypass = false
        })
      })
      
      this.$nextTick(()=>{
        this.services.forEach(x=>{
          this.tempPost.services.forEach(y=>{
            if(x.name === y.name){
              x.selected = y.selected
              x.bypass = y.bypass
            }
          })
        })
      })

      this.$nextTick(()=>{
        this.$bvModal.show('modal-services');
      })  
      
    },
    getGroupName(id){
      if(id == null)
        return null
      return  this.groups.find(x=>x._id == id).use_proxy ? "[UserProxy] "+this.groups.find(x=>x._id == id).name: "[Bypass] " +this.groups.find(x=>x._id == id).name 
    },
    onSVSubmit(event){
      event.preventDefault()
      this.tempPost.services =  this.services.filter(x=>x.selected).map((u) => {
        const value = Object.assign({},u)
        return value
      })
      this.settings.services = this.services.filter(x=>x.selected).map((u) => {
        const value = Object.assign({},u)
        return value
      })
      this.$nextTick(() => {      
        this.$bvModal.hide('modal-services');
      })
    },
    abortVS(event){
      //console.log('abort')
    },
    
    onSubmit(event){
      event.preventDefault()
      this.busy = true
      //console.log(this.tempPost)
      NPORT.addPort(this.tempPost).then(rs=>{
       
        if(rs.data.success){
          this.proxies.push(rs.data.data)
          this.$bvModal.hide('modal-edit-account');
          this.totalRows = this.proxies.length
          this.$bvToast.toast(`Port ${rs.data.data.port} Created`, {
            title: "Success",
            variant:"success",
            solid: true,
          }) 
        }else{
          this.$bvToast.toast(rs.data.errMsg, {
            title: "Error",
            variant:"danger",
            solid: true,
          })  
        }
        this.busy = false

      })
    },
    onApply(){
      let ids = this.proxies.filter(x=>x.selected).map(x=>x._id)
      let settings = Object.assign({}, this.settings)
      settings.services = this.settings.services.filter(x=>x.selected)

      NPORT.editPort({ids: ids, settings: settings}).then(rs=>{
        this.proxies.forEach(x=>{
          if(ids.includes(x._id))
          {
            x.keyport = settings.keyport
            x.rotate = settings.rotate
            x.country = settings.country
            //x.services = settings.services
            x.status = settings.status
            x.proxy_type = settings.proxy_type
          }
        })
      })
    },
    onDelete(){
      let ids = this.proxies.filter(x=>x.selected).map(x=>x._id)
      
      if(ids.length <= 0)
        return

      if(ids.length >= 20)
      {
        this.$swal({
          title: 'Warning',
          text: 'You can only change maxium 20 ports per one time',
          icon: 'warning',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.busy = true
          NPORT.deletePort(ids).then(rs=>{
            this.proxies = this.proxies.filter(x=>{
              return !ids.includes(x._id)
            })
            this.busy = false
          })
          
        }
      })
    },
    clonePort(id){
      if(this.base_proxies.length > 2000){
        this.$bvToast.toast("Clone port limit at 2000", {
            title: "Error",
            variant:"danger",
            solid: true,
          })  
          return
      }
      if(this.table_busy)
      {
        this.$bvToast.toast("Don't tap quickly", {
            title: "Error",
            variant:"danger",
            solid: true,
          })  
          return
      }
      this.table_busy = true
      let slPort = parseInt(id.port)

      let nport = slPort;//(this.proxies.map(x=>parseInt(x.port)).sort().pop())+1
      let listPort = this.proxies.map(x=>parseInt(x.port))

      while(nport <= 64000){
        nport++
        if (!listPort.includes(nport))
          break
      }

      let listK = this.proxies.filter(x=>x.keyport==id.keyport && x.nameport).map(x=>x.nameport).sort().pop()
      let namep = parseInt(listK.split('_')[1])+1
      let nameport = id.nameport.split('_')[0]+'_'+namep

      NPORT.clonePort({id: id._id, nameport: nameport, port: nport }).then(rs=>{
        if(rs.data.success){
          this.proxies.push(rs.data.data)
          this.$bvToast.toast(`New Port Add ${rs.data.data.port}`, {
            title: `${rs.data.data.port}`,
            variant:"success",
            toaster: "b-toaster-bottom-center",
            solid: true,
            appendToast: true
          })
          this.totalRows = this.proxies.length
          this.select_port = this.proxies.filter(x=>x.selected).map(x=> x.port)
        }else{
          this.$bvToast.toast(`Clone Port Error: ${rs.data.errMsg}`, {
            title: id.port,
            variant:"danger",
            toaster: "b-toaster-bottom-center",
            solid: true,
            appendToast: true
          })
        }
        setTimeout(()=>{
          this.table_busy = false
        },1500)
        
      })
    },
    changeIp(id){
      NPORT.changeIp(id._id).then(rs=>{
        if(rs.data.success){
          this.$bvToast.toast(`IP changed`, {
            title: id.port,
            variant:"success",
            toaster: "b-toaster-bottom-center",
            solid: true,
            appendToast: true
          })
        }else{
          this.$bvToast.toast(`Change IP Time Error: ${rs.data.errMsg}`, {
            title: id.port,
            variant:"danger",
            toaster: "b-toaster-bottom-center",
            solid: true,
            appendToast: true
          })
        }
      })
    },
    
    createWip(id){
      let price = this.$store.getters['auth/settings'].price_per_port

      this.$swal({
        title: 'Are you sure?',
        text: `You will paid ${price}$ for create wip port`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Continue',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          NPORT.createWip(id._id).then(rs=>{
            if(rs.data.success){
              this.$swal({
                icon: 'success',
                title: 'Created!',
                text: 'Your wip port has been created.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.$store.dispatch('auth/get_uinfo')
             // console.log(rs)
              this.proxies.find(x=>x._id == id._id).wip = rs.data.data.wip
              //this.$refs.table.refresh()
            }else{
              this.$swal({
                icon: 'danger',
                title: 'Error',
                text: rs.data.errMsg,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            }
          })
          
        }
      })
    },
    renewWip(id){
      let price = this.$store.getters['auth/settings'].price_per_port

      this.$swal({
        title: 'Are you sure?',
        text: `You will paid ${price}$ for renew wip port 30 days`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Continue',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          NPORT.renewWip(id._id).then(rs=>{
            if(rs.data.success){
              this.$swal({
                icon: 'success',
                title: 'Created!',
                text: 'Your wip port has been renew.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.$store.dispatch('auth/get_uinfo')
              this.proxies.find(x=>x._id == id._id).wip.date_expired = rs.data.date
            }else{
              this.$swal({
                icon: 'danger',
                title: 'Error',
                text: rs.data.errMsg,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            }
          })
          
        }
      })
    },
    enableLog(id,en){
      NPORT.enableLog(id._id,en).then(rs=>{
        if(rs.data.success){
          this.$bvToast.toast(`Log ${rs.data.enable ? 'enabled': 'disabled'}`, {
            title: id.port,
            variant:"success",
            toaster: "b-toaster-bottom-center",
            solid: true,
            appendToast: true
          })
          this.proxies.find(x=>x._id == id._id).wip.log_enable = rs.data.enable
          this.lports = this.proxies.filter(x=>x.wip && x.wip.log_enable)
        }else{
          this.$bvToast.toast(`${rs.data.enable ? 'Enable' : 'Disable'} Log Error: ${rs.data.errMsg}`, {
            title: id.port,
            variant:"danger",
            toaster: "b-toaster-bottom-center",
            solid: true,
            appendToast: true
          })
        }
      })
    },
    sortingChanged(a, b, key) {
      if(key == "wipport"){
        return parseInt(a.wip.host.split('.')[0].replace('ip','')) <  parseInt(b.wip.host.replace('ip','')) ? -1 : 1
      }
      return true
    },
    deleteWip(id){
      
      this.$swal({
        title: 'Are you sure?',
        text: `You cannot revert this`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Delete it',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          NPORT.deleteWip(id._id).then(rs=>{
            if(rs.data.success){
              this.$swal({
                icon: 'success',
                title: 'Delete!',
                text: 'Your wip port has been delete.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              //this.$store.dispatch('auth/get_uinfo')
              this.proxies.find(x=>x._id == id._id).wip = rs.data.data.wip
            }else{
              this.$swal({
                icon: 'danger',
                title: 'Error',
                text: rs.data.errMsg,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            }
          })
          
        }
      })
    },
    onChangeKeyPort(){
      let ids = this.proxies.filter(x=>x.selected).map(x=>x._id)
      let settings = Object.assign({}, this.settings)
      NPORT.changeKeyport({ids: ids, keyport: settings.keyport}).then(rs=>{
        this.proxies.forEach(x=>{
          if(ids.includes(x._id))
          {
            x.keyport = settings.keyport
          }
        })
      })

    },
    onChangeGeo(){
      let ids = this.proxies.filter(x=>x.selected).map(x=>x._id)
      let settings = Object.assign({}, this.settings)
      //console.log(settings)
      ids.forEach(x=>{
        setTimeout(()=>{
          NPORT.changeGeo({
            id: x,
            country: settings.country,
            ctsett: settings.ctsett,
            state: settings.state,
            city: settings.city
          }).then(rs=>{
            let cport = this.proxies.find(c=>c._id == x)
            if(rs.data.success){
              
              cport.country = settings.country
              cport.ctsett = settings.ctsett
              cport.state = settings.state
              cport.city = settings.city
              this.$bvToast.toast(`Geo changed`, {
                title: cport.port,
                variant:"success",
                toaster: "b-toaster-bottom-center",
                solid: true,
                appendToast: true
              })
            }else{
              this.$bvToast.toast(`Change Geo Error: ${rs.data.errMsg}`, {
                title: cport.port,
                variant:"danger",
                toaster: "b-toaster-bottom-center",
                solid: true,
                appendToast: true
              })
            }
          })
        },1000)
      })
    },
    onChangeGroup(){
      let ids = this.proxies.filter(x=>x.selected).map(x=>x._id)
      let settings = Object.assign({}, this.settings)

      ids.forEach(x=>{
        setTimeout(()=>{
          NPORT.changeGroupBypass({
            id: x,
            group_id: settings.group_id
          }).then(rs=>{
            if(rs.data.success){
              let cport = this.proxies.find(c=>c._id == x)
              cport.group_id = settings.group_id

              this.$bvToast.toast(`Group Bypass changed`, {
                title: cport.port,
                variant:"success",
                toaster: "b-toaster-bottom-center",
                solid: true,
                appendToast: true
              })
            }else{
              this.$bvToast.toast(`Change Group Bypass  Error: ${rs.data.errMsg}`, {
                title: cport.port,
                variant:"danger",
                toaster: "b-toaster-bottom-center",
                solid: true,
                appendToast: true
              })
            }
          })
        },1000)
      })
    },
    onChangeRoatate(){
      let ids = this.proxies.filter(x=>x.selected).map(x=>x._id)
      let settings = Object.assign({}, this.settings)

      ids.forEach(x=>{
        setTimeout(()=>{
          NPORT.changeRotate({
            id: x,
            rotate: settings.rotate
          }).then(rs=>{
            if(rs.data.success){
              let cport = this.proxies.find(c=>c._id == x)
              cport.rotate = settings.rotate

              this.$bvToast.toast(`Rotate Time changed`, {
                title: cport.port,
                variant:"success",
                toaster: "b-toaster-bottom-center",
                solid: true,
                appendToast: true
              })
            }else{
              this.$bvToast.toast(`Change Rotate Time Error: ${rs.data.errMsg}`, {
                title: cport.port,
                variant:"danger",
                toaster: "b-toaster-bottom-center",
                solid: true,
                appendToast: true
              })
            }
          })
        },1000)
      })
    },
    onChangeServices(){
      let ids = this.proxies.filter(x=>x.selected).map(x=>x._id)
      let settings = Object.assign({}, this.settings)

      ids.forEach(x=>{
        //console.log(this.tempPost.services)
        setTimeout(()=>{
          NPORT.changeServices({
            id: x,
            services: this.tempPost.services
          }).then(rs=>{
            if(rs.data.success){
              let cport = this.proxies.find(c=>c._id == x)
              cport.services = this.tempPost.services

              this.$bvToast.toast(`Service changed`, {
                title: cport.port,
                variant:"success",
                toaster: "b-toaster-bottom-center",
                solid: true,
                appendToast: true
              })
            }else{
              this.$bvToast.toast(`Change Service Error: ${rs.data.errMsg}`, {
                title: cport.port,
                variant:"danger",
                toaster: "b-toaster-bottom-center",
                solid: true,
                appendToast: true
              })
            }
          })
        },1000)
      })
    },
    onStatusChange(evt){
      let ids = this.proxies.filter(x=>x.selected).map(x=>x._id)
      //console.log(ids)
      //let settings = Object.assign({}, this.settings)

      ids.forEach(x=>{
        setTimeout(()=>{
          NPORT.changeStatus({
            id: x,
            status: evt
          }).then(rs=>{
            if(rs.data.success){
              let cport = this.proxies.find(c=>c._id == x)
              cport.status = evt

              this.$bvToast.toast(`Status changed`, {
                title: cport.port,
                variant:"success",
                toaster: "b-toaster-bottom-center",
                solid: true,
                appendToast: true
              })
            }else{
              let cport = this.proxies.find(c=>c._id == x)
              this.$bvToast.toast(`Status change Error: ${rs.data.errMsg}`, {
                title: cport.port,
                variant:"danger",
                toaster: "b-toaster-bottom-center",
                solid: true,
                appendToast: true
              })
            }
          })
        },1000)
      })
    },
    sortCompare(ctx) {
      console.log(ctx)
    },
    onExport(){
      let ids = this.proxies.filter(x=>x.selected)
      let data_export = []
      ids.forEach(x=>{
        data_export.push(`zone-${this.$store.getters['auth/userInfo'].username}.vip-port-${x.port}:${this.$store.getters['auth/userInfo'].proxy_pass}`)
        //data_export.push(`zone-${this.$store.getters['auth/userInfo'].username}.vip-port-${x.port}:${this.$store.getters['auth/userInfo'].proxy_pass}`)
      })

      var a = document.createElement("a");
      var json = data_export.join('\n'),
      blob = new Blob([json], {type: "octet/stream"}),
      url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = "export.txt";
      a.click();
      window.URL.revokeObjectURL(url);
    },

    exportSession(){
      let ids = this.proxies.filter(x=>x.selected)
      if(ids.length <= 0)
        return
      this.$swal({
        title: 'How many Sessions for a Port?',
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off',
          type: 'number'
        },
        showCancelButton: true,
        confirmButtonText: 'Export',
        showLoaderOnConfirm: true,
        preConfirm: (number) => {
          return parseInt(number)
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        
        if (result.isConfirmed) {
          if(isNaN(result.value)){
            
            this.$bvToast.toast(`Please type number, not string`, {
                title: 'Error',
                variant:"danger",
                toaster: "b-toaster-bottom-center",
                solid: true,
                appendToast: true
            })
            return
          }

          if(result.value > 10000){
            this.$bvToast.toast(`Max session is 10000`, {
                title: 'Error',
                variant:"danger",
                toaster: "b-toaster-bottom-center",
                solid: true,
                appendToast: true
            })
            return
          }
          let value = parseInt(result.value)
          //console.log(value)
          if(value == NaN){
            return
          }
          let ids = this.proxies.filter(x=>x.selected)
          let data_export = []
          ids.forEach(x=>{
            function makeid(length) {
                return crypto.randomBytes(length).toString("hex")
                // let result = '';
                // const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                // const charactersLength = characters.length;
                // let counter = 0;
                // while (counter < length) {
                //   result += characters.charCodeAt(Math.floor(Math.random() * charactersLength)).toString(16)

                //   counter += 1;
                // }

                // return result;
            }
            for(let i=0;i<value;i++){
              const a = x.city || x.state || null
              if(a)
              {
                const cc = x.city ? "city" : "state"
                //data_export.push(`get.fastproxy.vip:2086:zone-${this.$store.getters['auth/userInfo'].username}.vip-port-${x.port}-country-${x.country.toLowerCase()}-${cc}-${a}-session-${makeid(8)}:${this.$store.getters['auth/userInfo'].proxy_pass}`)
                data_export.push(`zone-${this.$store.getters['auth/userInfo'].username}.vip-port-${x.port}-country-${x.country.toLowerCase()}-${cc}-${a}-session-${makeid(6)}:${this.$store.getters['auth/userInfo'].proxy_pass}@get.fastproxy.vip:2086`)
              }
              else{
                data_export.push(`zone-${this.$store.getters['auth/userInfo'].username}.vip-port-${x.port}-country-${x.country.toLowerCase()}-session-${makeid(6)}:${this.$store.getters['auth/userInfo'].proxy_pass}@get.fastproxy.vip:2086`)
              }
            }
            
          })
          var a = document.createElement("a");
          var json = data_export.join('\n'),
          blob = new Blob([json], {type: "octet/stream"}),
          url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = "export-session.txt";
          a.click();
          window.URL.revokeObjectURL(url);
        }
      })
    },

    exportSessionOld(){
      let ids = this.proxies.filter(x=>x.selected)
      if(ids.length <= 0)
        return
      this.$swal({
        title: 'How many Sessions for a Port?',
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off',
          type: 'number'
        },
        showCancelButton: true,
        confirmButtonText: 'Export',
        showLoaderOnConfirm: true,
        preConfirm: (number) => {
          return parseInt(number)
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        
        if (result.isConfirmed) {
          if(isNaN(result.value)){
            
            this.$bvToast.toast(`Please type number, not string`, {
                title: 'Error',
                variant:"danger",
                toaster: "b-toaster-bottom-center",
                solid: true,
                appendToast: true
            })
            return
          }

          if(result.value > 10000){
            this.$bvToast.toast(`Max session is 10000`, {
                title: 'Error',
                variant:"danger",
                toaster: "b-toaster-bottom-center",
                solid: true,
                appendToast: true
            })
            return
          }
          let value = parseInt(result.value)
          //console.log(value)
          if(value == NaN){
            return
          }
          let ids = this.proxies.filter(x=>x.selected)
          let data_export = []
          ids.forEach(x=>{
            function makeid(length) {
                return crypto.randomBytes(length).toString("hex");
                // let result = '';
                // const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                // const charactersLength = characters.length;
                // let counter = 0;
                // while (counter < length) {
                //   result += characters.charCodeAt(Math.floor(Math.random() * charactersLength)).toString(16)

                //   counter += 1;
                // }

                // return result;
            }
            for(let i=0;i<value;i++){
              const a = x.city || x.state || null
              if(a)
              {
                const cc = x.city ? "city" : "state"
                data_export.push(`get.fastproxy.vip:2086:zone-${this.$store.getters['auth/userInfo'].username}.vip-port-${x.port}-country-${x.country.toLowerCase()}-${cc}-${a}-session-${makeid(4)}:${this.$store.getters['auth/userInfo'].proxy_pass}`)
                //data_export.push(`zone-${this.$store.getters['auth/userInfo'].username}.vip-port-${x.port}-country-${x.country.toLowerCase()}-${cc}-${a}-session-${makeid(8)}:${this.$store.getters['auth/userInfo'].proxy_pass}@get.fastproxy.vip:2086`)
              }
              else{
                data_export.push(`get.fastproxy.vip:2086:zone-${this.$store.getters['auth/userInfo'].username}.vip-port-${x.port}-country-${x.country.toLowerCase()}-session-${makeid(4)}:${this.$store.getters['auth/userInfo'].proxy_pass}`)
              }
            }
            
          })
          var a = document.createElement("a");
          var json = data_export.join('\n'),
          blob = new Blob([json], {type: "octet/stream"}),
          url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = "export-session.txt";
          a.click();
          window.URL.revokeObjectURL(url);
        }
      })
    },

    onExportAuth(old){
      let ids = this.proxies.filter(x=>x.selected)
      let data_export = []
      ids.forEach(x=>{
        if(old)
          data_export.push(`get.fastproxy.vip:2086:zone-${this.$store.getters['auth/userInfo'].username}.vip-port-${x.port}:${this.$store.getters['auth/userInfo'].proxy_pass}`)
        else
          data_export.push(`zone-${this.$store.getters['auth/userInfo'].username}.vip-port-${x.port}:${this.$store.getters['auth/userInfo'].proxy_pass}@get.fastproxy.vip:2086`)
      })

      var a = document.createElement("a");
      var json = data_export.join('\n'),
      blob = new Blob([json], {type: "octet/stream"}),
      url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = "export.txt";
      a.click();
      window.URL.revokeObjectURL(url);
    },

    onExportWIP(){
      let ids = this.proxies.filter(x=>x.selected)
      let data_export = []
      // ids.forEach(x=>{
      //   if(x.wip.used)
      //     data_export.push(`${x.wip.host}:${x.wip.port}`)
      // })

      data_export = ids.filter(x=>x.wip.used).map(x=> {
        return {
          host: x.wip.host,
          port: parseInt(x.wip.port)
        }
      }).sort((a,b)=>{
        return parseInt(a.host.split('.')[0].replace('ip','')) <  parseInt(b.host.replace('ip','')) ? -1 : 1
      }).map(x=>{
        return `${x.host}:${x.port}`
      })

      var a = document.createElement("a");
      var json = data_export.join('\n'),
      blob = new Blob([json], {type: "octet/stream"}),
      url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = "export.txt";
      a.click();
      window.URL.revokeObjectURL(url);
    },
    onRandomKeyport(){
      const strs = "qwertyuioplkjhgfdsazxcvbnmQWERTYUIOPLKJHGFDSAZXCVBNM0123456789!@#$^*()_+=";
      var str = "";
      for (let index = 0; index < 16; index++) {
          str += strs[Math.floor(Math.random() * strs.length)];
      }
      this.tempPost.keyport = str
      
    },
    onHidePort(){
      let ids = this.proxies.filter(x=>x.selected)
      let check = 0;//ids.filter(x=>x.wip.used)
      if(check.length > 0){
        this.$bvToast.toast(`You need to delete wip port first: ${check.map(x=>x.port).join(',')}`, {
          title: "Error",
          variant:"danger",
          toaster: "b-toaster-bottom-center",
          solid: true,
          appendToast: true
        })
      }else{
        NPORT.deletePort(ids.map(y=>y._id)).then(rs=>{
          if(rs.data.success){
            rs.data.data.ports_success.forEach(x=>{
              let cport = this.proxies.find(c=>c._id == x)
              let index = this.proxies.findIndex(c=>c._id == x)
              this.proxies.splice(index,1)
              this.$bvToast.toast(`Port deleted`, {
                title: cport.port,
                variant:"success",
                toaster: "b-toaster-bottom-center",
                solid: true,
                appendToast: true
              })

            })
            rs.data.data.ports_notfound.forEach(x=>{
              let cport = this.proxies.find(c=>c._id == x)
              let index = this.proxies.findIndex(c=>c._id == x)
              this.proxies.splice(index,1)
              this.$bvToast.toast(`Port deleted`, {
                title: cport.port,
                variant:"success",
                toaster: "b-toaster-bottom-center",
                solid: true,
                appendToast: true
              })
            })
          }else{
            this.$bvToast.toast(`${rs.data.errMsg}`, {
                title: "Delete Error",
                variant:"danger",
                toaster: "b-toaster-bottom-center",
                solid: true,
                appendToast: true
            })
          }
        })
      }
      // ids.forEach(x=>{
      //   if(x.wip.used){
      //     this.$bvToast.toast(`You need to delete wip port first`, {
      //           title: x.port,
      //           variant:"danger",
      //           toaster: "b-toaster-bottom-center",
      //           solid: true,
      //           appendToast: true
      //         })
      //   }else{
      //     // setTimeout(()=>{
      //     //   NPORT.deletePort({
      //     //     id: x._id,
      //     //     status: false
      //     //   }).then(rs=>{
      //     //     if(rs.data.success){
      //     //       let cport = this.proxies.find(c=>c._id == x)
      //     //       cport.hide = true
      //     //       let index = this.proxies.findIndex(c=>c._id == x)
      //     //       this.proxies.splice(index,1)
      //     //       this.$bvToast.toast(`Port deleted`, {
      //     //         title: cport.port,
      //     //         variant:"success",
      //     //         toaster: "b-toaster-bottom-center",
      //     //         solid: true,
      //     //         appendToast: true
      //     //       })
      //     //     }else{
      //     //       this.$bvToast.toast(`Delete port Error: ${rs.data.errMsg}`, {
      //     //         title: cport.port,
      //     //         variant:"danger",
      //     //         toaster: "b-toaster-bottom-center",
      //     //         solid: true,
      //     //         appendToast: true
      //     //       })
      //     //     }
      //     //   })
      //     // },1000)

          
      // }
    },
    selectAll(){
      this.$refs.table.refresh()
      this.$nextTick(()=>{
        //console.log(this.$refs.table.$refs['item-rows'])
        this.$refs.table.$refs['item-rows'].forEach(x=>{
          this.proxies[parseInt(x.$attrs['aria-rowindex']) - 1].selected = true
        })
      })
      


      //console.log(this.filter)
      // this.proxies.forEach(x=>x.selected = true)
      this.$nextTick(() => {      
        this.$refs.table.refresh()
        this.onSelected()
      })
    },
    clearSelect(){
      this.proxies.forEach(x=>x.selected = false)
      this.$nextTick(() => {      
        this.$refs.table.refresh()
        this.onSelected()
      })
    },
    formatBytes(bytes) {
      if(bytes == undefined)
        bytes = 0
      var marker = 1000; // Change to 1000 if required
      var decimal = 2; // Change as required
      var kiloBytes = marker; // One Kilobyte is 1024 bytes
      var megaBytes = marker * marker; // One MB is 1024 KB
      var gigaBytes = marker * marker * marker; // One GB is 1024 MB
      var teraBytes = marker * marker * marker * marker; // One TB is 1024 GB
      // return bytes if less than a KB
      if(bytes < kiloBytes) return bytes + " Bytes";
      // return KB if less than a MB
      else if(bytes < megaBytes) return(bytes / kiloBytes).toFixed(decimal) + " KB";
      // return MB if less than a GB  ``
      else if(bytes < gigaBytes) return(bytes / megaBytes).toFixed(decimal) + " MB";
      // return GB if less than a TB
      else if(bytes < teraBytes) return (bytes / gigaBytes).toFixed(decimal) + " GB";
      
      else return (bytes / teraBytes).toFixed(decimal) + " TB";
    },
    getstateState(ct){
      let state = this.targetState.find(x=>  x.countrycode == ct) ? this.targetState.find(x=>  x.countrycode == ct)['states'] : null
      if(state){
        this.lstate = state.sort()
      }else{
        this.lstate = []
      }
      
    },
    getCity(ct){
      let state = this.targetCity.find(x=>  x.countrycode == ct) ? this.targetCity.find(x=>  x.countrycode == ct)['cities'] : null
      if(state){
        this.lcity = state.sort()
      }else{
        this.lcity = []
      }
      
    },
    changeCtSett(sl,ct){
      //console.log(ct)
      switch(sl){
        case 0:
          this.settings.state = ""
          this.settings.city = ""
          this.tempPost.state = ""
          this.tempPost.city = ""
          break;
        case 1:
          this.getstateState(ct)
          if(this.settings.state == ''){
            this.settings.state = this.lstate[0]
            this.tempPost.state = this.lstate[0]
          }
          
          this.settings.city = ""
          
          this.tempPost.city = ""
          break;
        case 2:
          this.getCity(ct)
          if(this.settings.city == ''){
            this.settings.city = this.lcity[0]
            this.tempPost.city = this.lcity[0]
          }
          
          this.settings.state = ""
          this.tempPost.state = ""
          
          break;
      }
    },
    getDate(c){
      let d = new Date(c)
      let t = d.getTime() - new Date(Date.now()).getTime()


      let diffDay =  (t / (1000 * 3600 * 24)).toFixed(0)
      return diffDay+" days"
    },
    addDays(date, days) {
      var result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    },
    clearLog(id){
      NPORT.clearLog(id).then(rs=>{
        console.log(rs)

      })
    },
    getLog(){
      setInterval(function () { 
        NPORT.getWipLog().then(rs=>{
          //console.log(rs)
          if(rs.data.success){
            this.proxies = rs.data.data  
            //console.log(this.proxies)
            
            this.lports = this.proxies.filter(x=>x.wip && x.wip.log_enable)
            //console.log(this.lports)
            this.totalRows = this.proxies.length
          }
        })
      }, 60000);
    },
    changeSetting(){
      let ids = this.proxies.filter(x=>x.selected).map(x=>x._id)
      if(ids <= 0)
        return
      this.setting_busy = true
      this.table_busy = true
      let settings = Object.assign({}, this.settings)
      const perChunk = 50
      const result = ids.reduce((resultArray,item,index) => {
        const chunkIndex = Math.floor(index/perChunk)

        if(!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = [] // start a new chunk
        }

        resultArray[chunkIndex].push(item)

        return resultArray
      },[])

      result.map(async (x,index)=>{
        let rs = await NPORT.changeSettings({ids: x, settings})
        if(rs.data.success){
          rs.data.data.ports_success.forEach(x=>{
              let cport = this.proxies.find(c=>c._id == x)
              //console.log(cport)
                cport.keyport = settings.keyport
                cport.country = settings.country
                
                cport.ctsett = settings.ctsett

                cport.state = settings.state
                cport.city = settings.city
                cport.group_id = settings.group_id
                cport.rotate = settings.rotate
                cport.services = settings.services
            })
          
            this.$bvToast.toast(`Setting changed`, {
              title: `${rs.data.data.ports_success.length} port changed`,
              variant:"success",
              toaster: "b-toaster-bottom-center",
              solid: true,
              appendToast: true
            })



          rs.data.data.ports_notfound.forEach(x=>{
            let cport = this.proxies.find(c=>c._id == x)

              this.$bvToast.toast(`Error: Port not found`, {
                title: cport.port,
                variant:"danger",
                toaster: "b-toaster-bottom-center",
                solid: true,
                appendToast: true
              })
          })
          rs.data.data.ports_disabled.forEach(x=>{
            let cport = this.proxies.find(c=>c._id == x)
              this.$bvToast.toast(`Error: Port disabled`, {
                title: cport.port,
                variant:"danger",
                toaster: "b-toaster-bottom-center",
                solid: true,
                appendToast: true
              })
          })
        }else{
          this.$bvToast.toast(`${rs.data.errMsg}`, {
                title: "Error",
                variant:"danger",
                toaster: "b-toaster-bottom-center",
                solid: true,
                appendToast: true
            })
        }
        if(index == (result.length - 1))
          this.setting_busy = this.table_busy = false
      })
      
      
    },
    onChangePortName(evt){
      evt.preventDefault()
      this.busy = true

      NPORT.changePortName(this.ports_change).then(rs=>{
        if(rs.data.success){
          const done_data = this.ports_change.filter(x=> rs.data.done.includes(x._id))
          done_data.forEach(x=>{
            //this.proxies[x._id].port = x.new_port
            let index = this.proxies.findIndex(y=>y._id === x._id )
            if (index != -1)
              this.proxies[index].port = x.new_port
          })
          this.$bvModal.hide('modal-change-port')
          this.$bvToast.toast(`${done_data.length} port change success`, {
                title: "Success",
                variant:"success",
                toaster: "b-toaster-bottom-center",
                solid: true,
                appendToast: true
            })
        }else{

        }
        this.busy = false
      })
    },
    changePort(){
      let ids = this.proxies.filter(x=>x.selected).map(x=>{
        return {
          _id: x._id,
          port: x.port,
          new_port: 0
        }
      })
      if(ids.length >= 20)
      {
        this.$swal({
          title: 'Warning',
          text: 'You can only change maxium 20 ports per one time',
          icon: 'warning',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }else{
        this.ports_change = ids
        this.$bvModal.show('modal-change-port')
      }
    },
    changeIP(){
      let ids = this.proxies.filter(x=>x.selected).map(x=>x._id)
      NPORT.changeIpMulti(ids).then(rs=>{
        if(rs.data.success){
            rs.data.data.ports_success.forEach(x=>{
              let cport = this.proxies.find(c=>c._id == x)
              this.$bvToast.toast(`Ip Changed`, {
                title: cport.port,
                variant:"success",
                toaster: "b-toaster-bottom-center",
                solid: true,
                appendToast: true
              })

            })
            // rs.data.data.ports_notfound.forEach(x=>{
            //   let cport = this.proxies.find(c=>c._id == x)
            //   let index = this.proxies.findIndex(c=>c._id == x)
            //   this.proxies.splice(index,1)
            //   this.$bvToast.toast(`Port deleted`, {
            //     title: cport.port,
            //     variant:"success",
            //     toaster: "b-toaster-bottom-center",
            //     solid: true,
            //     appendToast: true
            //   })
            // })
          }else{
            this.$bvToast.toast(`${rs.data.errMsg}`, {
                title: "IP Change Error",
                variant:"danger",
                toaster: "b-toaster-bottom-center",
                solid: true,
                appendToast: true
            })
          }
      })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>