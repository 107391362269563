<template>
  <b-card>
    <!-- form -->
    
    <validation-observer ref="simpleRules">
    <b-form>
      <b-row>

         <!-- old password -->
        <b-col cols="6">
          <b-form-group
            label="Password"
            label-for="a-password"
          >
            <validation-provider
              #default="{ errors }"
              name="Password"
              vid="Password"
              rules="required|min:8"
            >
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="a-password"
                  v-model="passwordValueOld"
                  :state="errors.length > 0 ? false:null"
                  type="password"
                  placeholder="Password"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
         <b-col cols="6"></b-col>
        <!-- new password -->
        <b-col cols="6">
          <b-form-group
            label="Password"
            label-for="a-password"
          >
            <validation-provider
              #default="{ errors }"
              name="Password"
              vid="Password"
              rules="required|min:8"
            >
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="a-password-1"
                  v-model="newPasswordValue"
                  :state="errors.length > 0 ? false:null"
                  type="password"
                  placeholder="Password"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!-- confirm password -->
        <b-col cols="6">
          <b-form-group
            label="Confirm Password"
            label-for="ac-password"
          >
            <validation-provider
              #default="{ errors }"
              name="Confirm Password"
              rules="required|confirmed:Password"
            >
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="ac-password"
                  v-model="RetypePassword"
                  :state="errors.length > 0 ? false:null"
                  type="password"
                  placeholder="Confirm Password"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!-- reset button -->
        <b-col cols="12">
          <b-button
            variant="primary"
            type="submit"
            @click.prevent="changePassword"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, min, confirmed,
} from '@validations'
import Ripple from 'vue-ripple-directive'
import RepositoryFactory from '../../../api/RepositoryFactory'
const UsersRepository = RepositoryFactory.get('user')

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      passwordValueOld: '',
      newPasswordValue: '',
      RetypePassword: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
      required: true,
      min: 8,
      confirmed: true,
      codePlaceholderArgument: true,
    }
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    changePassword(){
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
           UsersRepository.changePassword({
            old_password: this.passwordValueOld,
            password: this.newPasswordValue
          }).then(rs => {
            console.log(rs)
            if(rs.data.success){
              this.$bvToast.toast("Password changed!", {
                title: 'Success',
                variant: 'success',
                solid: false,
              })
            }else{
              this.$bvToast.toast(rs.data.errMsg, {
                title: 'Error',
                variant: 'danger',
                solid: false,
              })
            }
          }).catch(e=>{
            this.$bvToast.toast('Error '+e.message, {
              title: 'Error',
              variant: 'danger',
              solid: false,
            })
          })
        }
      })
     
    }
  },
}
</script>
